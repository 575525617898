import m from 'mithril'
import stream from 'mithril/stream'
import classNames from 'classnames'
import {
    Profile
} from 'app/models'
import moment from 'moment'
import {
    Dialog,
    TextBox,
    TextArea,
} from 'components'


class DashboardPage {
    constructor() {
        this.model = new Profile()
        this.elseone = ""
        this.elsetwo = ""
        this.elsethree = ""
        this.elsefour = ""
        this.temp = 0
        this.send_request = false

    }

    view() {
        return [
            m("",{
                style:{
                    background:"#d4eae9"
                }
            },[
            m(".container.py-4[id='examples']",{
                // style:{
                //     backgroundColor: "#d4eae9"
                // }
            },
                m("div.row",
                    m("div.col-lg-12.order-1.order-lg-2",
                        [
                            m("div.overflow-hidden.mb-1.text-center",
                                m("h2.font-weight-normal.text-7.mb-0.",
                                    m("strong.font-weight-extra-bold",
                                        "請先選擇報名的日期 - 可複選"
                                    )
                                )
                            ),
                            m("hr.my-5"),
                            m("div.form-row",
                                m("div.form-group.col-lg-12",
                                    m("div.custom-checkbox-1",
                                        [
                                            m("input[id='createAccount'][type='checkbox'][name='createAccount'][value='1']", {
                                                onclick: (e) => {
                                                    if (this.model.isqone) {
                                                        this.model.isqone = false
                                                    } else {
                                                        this.model.isqone = true
                                                    }
                                                }
                                            }),
                                            m("label[for='createAccount ']", { "style": { "color": "#347b78" } },
                                                "05/12 - 在對病患衛教時，哪一個因素最會影響溝通的成效呢?"
                                            )
                                        ]
                                    )
                                )
                            ),
                            m("div.form-row",
                                m("div.form-group.col-lg-12",
                                    m("div.form-group.col",
                                        [
                                            m("div.form-check.form-check-inline",
                                                m("label.form-check-label",
                                                    [
                                                        m("input.form-check-input[type='checkbox'][name='radios'][data-msg-required='Please select at least one option.'][id='tabContent9Radio1'][value='option1'][required]", {
                                                            onclick: (e) => {
                                                                if (this.model.qone.indexOf("性別") < 0) {
                                                                    this.model.qone.push("性別")
                                                                } else {
                                                                    this.model.qone.splice(this.model.qone.indexOf("性別"), 1)
                                                                }
                                                            }
                                                        }),
                                                        "性別 "
                                                    ]
                                                )
                                            ),
                                            m("div.form-check.form-check-inline",
                                                m("label.form-check-label",
                                                    [
                                                        m("input.form-check-input[type='checkbox'][name='radios'][data-msg-required='Please select at least one option.'][id='tabContent9Radio1'][value='option1'][required]", {
                                                            onclick: (e) => {
                                                                if (this.model.qone.indexOf("年齡") < 0) {
                                                                    this.model.qone.push("年齡")
                                                                } else {
                                                                    this.model.qone.splice(this.model.qone.indexOf("年齡"), 1)
                                                                }
                                                            }
                                                        }),
                                                        "年齡 "
                                                    ]
                                                )
                                            ),
                                            m("div.form-check.form-check-inline",
                                                m("label.form-check-label",
                                                    [
                                                        m("input.form-check-input[type='checkbox'][name='radios'][data-msg-required='Please select at least one option.'][id='tabContent9Radio2'][value='option2'][required]", {
                                                            onclick: (e) => {
                                                                if (this.model.qone.indexOf("教育程度") < 0) {
                                                                    this.model.qone.push("教育程度")
                                                                } else {
                                                                    this.model.qone.splice(this.model.qone.indexOf("教育程度"), 1)
                                                                }
                                                            }
                                                        }),
                                                        " 教育程度 "
                                                    ]
                                                )
                                            ),
                                            m("div.form-check.form-check-inline",
                                                m("label.form-check-label",
                                                    [
                                                        m("input.form-check-input[type='checkbox'][name='radios'][data-msg-required='Please select at least one option.'][id='tabContent9Radio3'][value='option3'][required]", {
                                                            onclick: (e) => {
                                                                if (this.model.qone.indexOf("職業") < 0) {
                                                                    this.model.qone.push("職業")
                                                                } else {
                                                                    this.model.qone.splice(this.model.qone.indexOf("職業"), 1)
                                                                }
                                                            }
                                                        }),
                                                        "職業 "
                                                    ]
                                                )
                                            ),
                                            m("div.form-check.form-check-inline",
                                                m("label.form-check-label",
                                                    [
                                                        m("input.form-check-input[type='checkbox'][name='radios'][data-msg-required='Please select at least one option.'][id='tabContent9Radio3'][value='option3'][required]", {
                                                            onclick: (e) => {
                                                                if (this.model.qone.indexOf("疾病時程") < 0) {
                                                                    this.model.qone.push("疾病時程")
                                                                } else {
                                                                    this.model.qone.splice(this.model.qone.indexOf("疾病時程"), 1)
                                                                }
                                                            }
                                                        }),
                                                        "疾病時程 "
                                                    ]
                                                )
                                            ),
                                            m("div.form-check.form-check-inline",
                                                m("label.form-check-label",
                                                    [
                                                        m("input.form-check-input[type='checkbox'][name='radios'][data-msg-required='Please select at least one option.'][id='tabContent9Radio3'][value='option3'][required]", {
                                                            onclick: (e) => {
                                                                if (this.model.qone.indexOf("語言") < 0) {
                                                                    this.model.qone.push("語言")
                                                                } else {
                                                                    this.model.qone.splice(this.model.qone.indexOf("語言"), 1)
                                                                }
                                                            }
                                                        }),
                                                        "語言 "
                                                    ]
                                                )
                                            ),
                                            m("div.form-check.form-check-inline",
                                                m("label.form-check-label",
                                                    [
                                                        m("input.form-check-input[type='checkbox'][name='radios'][data-msg-required='Please select at least one option.'][id='tabContent9Radio3'][value='option3'][required]", {
                                                            onclick: (e) => {
                                                                if (this.model.qone.indexOf("其他") < 0) {
                                                                    this.model.qone.push("其他")
                                                                } else {
                                                                    this.model.qone.splice(this.model.qone.indexOf("其他"), 1)
                                                                }
                                                            }
                                                        }),
                                                        "其他 "
                                                    ]
                                                )
                                            ),
                                            m("div.form-check.form-check-inline",
                                                m(TextBox, {
                                                    oninput: (e) => {
                                                        this.elseone = e.target.value
                                                    },
                                                    value: this.elseone,
                                                    type: 'text',
                                                    placeholder: '請填寫其他答案',
                                                }),
                                            )
                                        ]
                                    )
                                )
                            ),
                            m("hr.my-5"),
                            m("div.form-row",
                                m("div.form-group.col-lg-12",
                                    m("div.custom-checkbox-1",
                                        [
                                            m("input[id='createAccount'][type='checkbox'][name='createAccount'][value='1']", {
                                                onclick: (e) => {
                                                    if (this.model.isqtwo) {
                                                        this.model.isqtwo = false
                                                    } else {
                                                        this.model.isqtwo = true
                                                    }
                                                }
                                            }),
                                            m("label[for='createAccount ']", { "style": { "color": "#347b78" } },
                                                "06/30- 針對AML的主題，您想多了解哪方面的相關知識呢?"
                                            )
                                        ]
                                    )
                                )
                            ),
                            m("div.form-row",
                                m("div.form-group.col-lg-12",
                                    m("div.form-group.col",
                                        [
                                            m("div.form-check.form-check-inline",
                                                m("label.form-check-label",
                                                    [
                                                        m("input.form-check-input[type='checkbox'][name='radios'][data-msg-required='Please select at least one option.'][id='tabContent9Radio1'][value='option1'][required]", {
                                                            onclick: (e) => {
                                                                if (this.model.qtwo.indexOf("疾病") < 0) {
                                                                    this.model.qtwo.push("疾病")
                                                                } else {
                                                                    this.model.qtwo.splice(this.model.qtwo.indexOf("疾病"), 1)
                                                                }
                                                            }
                                                        }),
                                                        "疾病 "
                                                    ]
                                                )
                                            ),
                                            m("div.form-check.form-check-inline",
                                                m("label.form-check-label",
                                                    [
                                                        m("input.form-check-input[type='checkbox'][name='radios'][data-msg-required='Please select at least one option.'][id='tabContent9Radio1'][value='option1'][required]", {
                                                            onclick: (e) => {
                                                                if (this.model.qtwo.indexOf("治療方式") < 0) {
                                                                    this.model.qtwo.push("治療方式")
                                                                } else {
                                                                    this.model.qtwo.splice(this.model.qtwo.indexOf("治療方式"), 1)
                                                                }
                                                            }
                                                        }),
                                                        "治療方式 "
                                                    ]
                                                )
                                            ),
                                            m("div.form-check.form-check-inline",
                                                m("label.form-check-label",
                                                    [
                                                        m("input.form-check-input[type='checkbox'][name='radios'][data-msg-required='Please select at least one option.'][id='tabContent9Radio2'][value='option2'][required]", {
                                                            onclick: (e) => {
                                                                if (this.model.qtwo.indexOf("衛教方式") < 0) {
                                                                    this.model.qtwo.push("衛教方式")
                                                                } else {
                                                                    this.model.qtwo.splice(this.model.qtwo.indexOf("衛教方式"), 1)
                                                                }
                                                            }
                                                        }),
                                                        " 衛教方式 "
                                                    ]
                                                )
                                            ),
                                            m("div.form-check.form-check-inline",
                                                m("label.form-check-label",
                                                    [
                                                        m("input.form-check-input[type='checkbox'][name='radios'][data-msg-required='Please select at least one option.'][id='tabContent9Radio3'][value='option3'][required]", {
                                                            onclick: (e) => {
                                                                if (this.model.qtwo.indexOf("副作用管理") < 0) {
                                                                    this.model.qtwo.push("副作用管理")
                                                                } else {
                                                                    this.model.qtwo.splice(this.model.qtwo.indexOf("副作用管理"), 1)
                                                                }
                                                            }
                                                        }),
                                                        "副作用管理 "
                                                    ]
                                                )
                                            ),
                                            m("div.form-check.form-check-inline",
                                                m("label.form-check-label",
                                                    [
                                                        m("input.form-check-input[type='checkbox'][name='radios'][data-msg-required='Please select at least one option.'][id='tabContent9Radio3'][value='option3'][required]", {
                                                            onclick: (e) => {
                                                                if (this.model.qtwo.indexOf("營養") < 0) {
                                                                    this.model.qtwo.push("營養")
                                                                } else {
                                                                    this.model.qtwo.splice(this.model.qtwo.indexOf("營養"), 1)
                                                                }
                                                            }
                                                        }),
                                                        "營養 "
                                                    ]
                                                )
                                            ),
                                            m("div.form-check.form-check-inline",
                                                m("label.form-check-label",
                                                    [
                                                        m("input.form-check-input[type='checkbox'][name='radios'][data-msg-required='Please select at least one option.'][id='tabContent9Radio3'][value='option3'][required]", {
                                                            onclick: (e) => {
                                                                if (this.model.qtwo.indexOf("其他") < 0) {
                                                                    this.model.qtwo.push("其他")
                                                                } else {
                                                                    this.model.qtwo.splice(this.model.qtwo.indexOf("其他"), 1)
                                                                }
                                                            }
                                                        }),
                                                        "其他 "
                                                    ]
                                                )
                                            ),
                                            m("div.form-check.form-check-inline",
                                                m(TextBox, {
                                                    oninput: (e) => {
                                                        this.elsetwo = e.target.value
                                                    },
                                                    value: this.elsetwo,
                                                    type: 'text',
                                                    placeholder: '請填寫其他答案',
                                                }),
                                            )
                                        ]
                                    )
                                )
                            ),
                            m("hr.my-5"),
                            m("div.form-row",
                                m("div.form-group.col-lg-12",
                                    m("div.custom-checkbox-1",
                                        [
                                            m("input[id='createAccount'][type='checkbox'][name='createAccount'][value='1']", {
                                                onclick: (e) => {
                                                    if (this.model.isqthree) {
                                                        this.model.isqthree = false
                                                    } else {
                                                        this.model.isqthree = true
                                                    }
                                                }
                                            }),
                                            m("label[for='createAccount']", { "style": { "color": "#347b78" } },
                                                "07/14 - 針對CLL的主題，您想多了解哪方面的相關知識呢?"
                                            )
                                        ]
                                    )
                                )
                            ),
                            m("div.form-row",
                                m("div.form-group.col-lg-12",
                                    m("div.form-group.col",
                                        [
                                            m("div.form-check.form-check-inline",
                                                m("label.form-check-label",
                                                    [
                                                        m("input.form-check-input[type='checkbox'][name='radios'][data-msg-required='Please select at least one option.'][id='tabContent9Radio1'][value='option1'][required]", {
                                                            onclick: (e) => {
                                                                if (this.model.qthree.indexOf("疾病") < 0) {
                                                                    this.model.qthree.push("疾病")
                                                                } else {
                                                                    this.model.qthree.splice(this.model.qthree.indexOf("疾病"), 1)
                                                                }
                                                            }
                                                        }),
                                                        "疾病 "
                                                    ]
                                                )
                                            ),
                                            m("div.form-check.form-check-inline",
                                                m("label.form-check-label",
                                                    [
                                                        m("input.form-check-input[type='checkbox'][name='radios'][data-msg-required='Please select at least one option.'][id='tabContent9Radio1'][value='option1'][required]", {
                                                            onclick: (e) => {
                                                                if (this.model.qthree.indexOf("治療方式") < 0) {
                                                                    this.model.qthree.push("治療方式")
                                                                } else {
                                                                    this.model.qthree.splice(this.model.qthree.indexOf("治療方式"), 1)
                                                                }
                                                            }
                                                        }),
                                                        "治療方式 "
                                                    ]
                                                )
                                            ),
                                            m("div.form-check.form-check-inline",
                                                m("label.form-check-label",
                                                    [
                                                        m("input.form-check-input[type='checkbox'][name='radios'][data-msg-required='Please select at least one option.'][id='tabContent9Radio2'][value='option2'][required]", {
                                                            onclick: (e) => {
                                                                if (this.model.qthree.indexOf("衛教方式") < 0) {
                                                                    this.model.qthree.push("衛教方式")
                                                                } else {
                                                                    this.model.qthree.splice(this.model.qthree.indexOf("衛教方式"), 1)
                                                                }
                                                            }
                                                        }),
                                                        " 衛教方式 "
                                                    ]
                                                )
                                            ),
                                            m("div.form-check.form-check-inline",
                                                m("label.form-check-label",
                                                    [
                                                        m("input.form-check-input[type='checkbox'][name='radios'][data-msg-required='Please select at least one option.'][id='tabContent9Radio3'][value='option3'][required]", {
                                                            onclick: (e) => {
                                                                if (this.model.qthree.indexOf("副作用管理") < 0) {
                                                                    this.model.qthree.push("副作用管理")
                                                                } else {
                                                                    this.model.qthree.splice(this.model.qthree.indexOf("副作用管理"), 1)
                                                                }
                                                            }
                                                        }),
                                                        "副作用管理 "
                                                    ]
                                                )
                                            ),
                                            m("div.form-check.form-check-inline",
                                                m("label.form-check-label",
                                                    [
                                                        m("input.form-check-input[type='checkbox'][name='radios'][data-msg-required='Please select at least one option.'][id='tabContent9Radio3'][value='option3'][required]", {
                                                            onclick: (e) => {
                                                                if (this.model.qthree.indexOf("營養") < 0) {
                                                                    this.model.qthree.push("營養")
                                                                } else {
                                                                    this.model.qthree.splice(this.model.qthree.indexOf("營養"), 1)
                                                                }
                                                            }
                                                        }),
                                                        "營養 "
                                                    ]
                                                )
                                            ),
                                            m("div.form-check.form-check-inline",
                                                m("label.form-check-label",
                                                    [
                                                        m("input.form-check-input[type='checkbox'][name='radios'][data-msg-required='Please select at least one option.'][id='tabContent9Radio3'][value='option3'][required]", {
                                                            onclick: (e) => {
                                                                if (this.model.qthree.indexOf("其他") < 0) {
                                                                    this.model.qthree.push("其他")
                                                                } else {
                                                                    this.model.qthree.splice(this.model.qthree.indexOf("其他"), 1)
                                                                }
                                                            }
                                                        }),
                                                        "其他 "
                                                    ]
                                                )
                                            ),
                                            m("div.form-check.form-check-inline",
                                                m("div.form-check.form-check-inline",
                                                    m(TextBox, {
                                                        oninput: (e) => {
                                                            this.elsethree = e.target.value
                                                        },
                                                        value: this.elsethree,
                                                        type: 'text',
                                                        placeholder: '請填寫其他答案',
                                                    }),
                                                )
                                            )
                                        ]
                                    )
                                )
                            ),
                            m("hr.my-5"),
                            m("div.form-row",
                                m("div.form-group.col-lg-12",
                                    m("div.custom-checkbox-1",
                                        [
                                            m("input[id='createAccount'][type='checkbox'][name='createAccount'][value='1']", {
                                                onclick: (e) => {
                                                    if (this.model.isqfour) {
                                                        this.model.isqfour = false
                                                    } else {
                                                        this.model.isqfour = true
                                                    }
                                                }
                                            }),
                                            m("label[for='createAccount']", { "style": { "color": "#347b78" } },
                                                "07/28 - 在衛教病患服用唯可來時，您常遇到的問題有哪些?"
                                            )
                                        ]
                                    )
                                )
                            ),
                            m("div.form-row",
                                m("div.form-group.col-lg-12",
                                    m("div.form-group.col",
                                        [
                                            m("div.form-check.form-check-inline",
                                                m("label.form-check-label",
                                                    [
                                                        m("input.form-check-input[type='checkbox'][name='radios'][data-msg-required='Please select at least one option.'][id='tabContent9Radio1'][value='option1'][required]", {
                                                            onclick: (e) => {
                                                                if (this.model.qfour.indexOf("病患服用唯可來後出現副作用(例如:cytopenia)時的用藥劑量調整") < 0) {
                                                                    this.model.qfour.push("病患服用唯可來後出現副作用(例如:cytopenia)時的用藥劑量調整")
                                                                } else {
                                                                    this.model.qfour.splice(this.model.qfour.indexOf("病患服用唯可來後出現副作用(例如:cytopenia)時的用藥劑量調整"), 1)
                                                                }
                                                            }
                                                        }),
                                                        "病患服用唯可來後出現副作用(例如:cytopenia)時的用藥劑量調整 "
                                                    ]
                                                )
                                            ),
                                            m("div.form-check.form-check-inline",
                                                m("label.form-check-label",
                                                    [
                                                        m("input.form-check-input[type='checkbox'][name='radios'][data-msg-required='Please select at least one option.'][id='tabContent9Radio1'][value='option1'][required]", {
                                                            onclick: (e) => {
                                                                if (this.model.qfour.indexOf("病患服用唯可來前，以及開始調整藥物劑量時，為什麼要頻繁的抽血監測數值變化") < 0) {
                                                                    this.model.qfour.push("病患服用唯可來前，以及開始調整藥物劑量時，為什麼要頻繁的抽血監測數值變化")
                                                                } else {
                                                                    this.model.qfour.splice(this.model.qfour.indexOf("病患服用唯可來前，以及開始調整藥物劑量時，為什麼要頻繁的抽血監測數值變化"), 1)
                                                                }
                                                            }
                                                        }),
                                                        "病患服用唯可來前，以及開始調整藥物劑量時，為什麼要頻繁的抽血監測數值變化 "
                                                    ]
                                                )
                                            ),
                                            m("div.form-check.form-check-inline",
                                                m("label.form-check-label",
                                                    [
                                                        m("input.form-check-input[type='checkbox'][name='radios'][data-msg-required='Please select at least one option.'][id='tabContent9Radio2'][value='option2'][required]", {
                                                            onclick: (e) => {
                                                                if (this.model.qfour.indexOf("病患器官功能不佳(例如:肝腎功能差)的服用藥物考量") < 0) {
                                                                    this.model.qfour.push("病患器官功能不佳(例如:肝腎功能差)的服用藥物考量")
                                                                } else {
                                                                    this.model.qfour.splice(this.model.qfour.indexOf("病患器官功能不佳(例如:肝腎功能差)的服用藥物考量"), 1)
                                                                }
                                                            }
                                                        }),
                                                        " 病患器官功能不佳(例如:肝腎功能差)的服用藥物考量 "
                                                    ]
                                                )
                                            ),
                                            m("div.form-check.form-check-inline",
                                                m("label.form-check-label",
                                                    [
                                                        m("input.form-check-input[type='checkbox'][name='radios'][data-msg-required='Please select at least one option.'][id='tabContent9Radio3'][value='option3'][required]", {
                                                            onclick: (e) => {
                                                                if (this.model.qfour.indexOf("NG Feeding") < 0) {
                                                                    this.model.qfour.push("NG Feeding")
                                                                } else {
                                                                    this.model.qfour.splice(this.model.qfour.indexOf("NG Feeding"), 1)
                                                                }
                                                            }
                                                        }),
                                                        "NG Feeding "
                                                    ]
                                                )
                                            ),
                                            m("div.form-check.form-check-inline",
                                                m("label.form-check-label",
                                                    [
                                                        m("input.form-check-input[type='checkbox'][name='radios'][data-msg-required='Please select at least one option.'][id='tabContent9Radio3'][value='option3'][required]", {
                                                            onclick: (e) => {
                                                                if (this.model.qfour.indexOf("病患漏服藥物") < 0) {
                                                                    this.model.qfour.push("病患漏服藥物")
                                                                } else {
                                                                    this.model.qfour.splice(this.model.qfour.indexOf("病患漏服藥物"), 1)
                                                                }
                                                            }
                                                        }),
                                                        "病患漏服藥物 "
                                                    ]
                                                )
                                            ),
                                            m("div.form-check.form-check-inline",
                                                m("label.form-check-label",
                                                    [
                                                        m("input.form-check-input[type='checkbox'][name='radios'][data-msg-required='Please select at least one option.'][id='tabContent9Radio3'][value='option3'][required]", {
                                                            onclick: (e) => {
                                                                if (this.model.qfour.indexOf("其他") < 0) {
                                                                    this.model.qfour.push("其他")
                                                                } else {
                                                                    this.model.qfour.splice(this.model.qfour.indexOf("其他"), 1)
                                                                }
                                                            }
                                                        }),
                                                        "其他 "
                                                    ]
                                                )
                                            ),
                                            m("div.form-check.form-check-inline",
                                                m(TextBox, {
                                                    oninput: (e) => {
                                                        this.elsefour = e.target.value
                                                    },
                                                    value: this.elsefour,
                                                    type: 'text',
                                                    placeholder: '請填寫其他答案',
                                                }),
                                            )
                                        ]
                                    )
                                )
                            ),
                            m("hr.my-5"),
                            m("div.card.mb-4",
                                m("div.card-body",
                                    m("div.row",
                                        m("div.col",
                                            m("form.contact-form[action='php/contact-form.php'][method='POST'][novalidate]",
                                                [
                                                    m("div.contact-form-success.alert.alert-success.d-none.mt-4",
                                                        [
                                                            m("strong",
                                                                "報名成功！!"
                                                            ),
                                                            " 你的訊息我們收到了！ "
                                                        ]
                                                    ),
                                                    m("div.contact-form-error.alert.alert-danger.d-none.mt-4",
                                                        [
                                                            m("strong",
                                                                "有錯誤!"
                                                            ),
                                                            " 請看看是不是少寫了些什麼？ ",
                                                            m("span.mail-error-message.text-1.d-block")
                                                        ]
                                                    ),
                                                    m("div.form-row",
                                                        [
                                                            m("div.form-group.col-lg-6",
                                                                [
                                                                    m(TextBox, {
                                                                        oninput: (e) => {
                                                                            this.model.name = e.target.value
                                                                        },
                                                                        value: this.model.name,
                                                                        type: 'text',
                                                                        label: '姓名',
                                                                        placeholder: '請輸入姓名',
                                                                        validate: () => {
                                                                            this.model.valid('name')
                                                                        },
                                                                        error: this.model.error('name'),
                                                                    })
                                                                ]
                                                            ),
                                                            m("div.form-group.col-lg-6",
                                                                [
                                                                    m(TextBox, {
                                                                        oninput: (e) => {
                                                                            this.model.idencard = e.target.value
                                                                        },
                                                                        value: this.model.idencard,
                                                                        type: 'text',
                                                                        label: '身分證字號',
                                                                        placeholder: '請輸入身分證字號',
                                                                        validate: () => {
                                                                            this.model.valid('idencard')
                                                                        },
                                                                        error: this.model.error('idencard'),
                                                                    })
                                                                ]
                                                            ),
                                                            m("div.form-group.col-lg-6",
                                                                [
                                                                    m(TextBox, {
                                                                        oninput: (e) => {
                                                                            this.model.organization = e.target.value
                                                                        },
                                                                        value: this.model.organization,
                                                                        type: 'text',
                                                                        label: '服務單位',
                                                                        placeholder: '請輸入服務單位',
                                                                    })
                                                                ]
                                                            ),
                                                            m("div.form-group.col-lg-6",
                                                                [
                                                                    m(TextBox, {
                                                                        oninput: (e) => {
                                                                            this.model.title = e.target.value
                                                                        },
                                                                        value: this.model.title,
                                                                        type: 'text',
                                                                        label: '職稱',
                                                                        placeholder: '請輸入職稱',
                                                                    })
                                                                ]
                                                            )
                                                        ]
                                                    ),
                                                    m("div.form-row",
                                                        m("div.form-group.col",
                                                            [
                                                                m(TextBox, {
                                                                    oninput: (e) => {
                                                                        this.model.email = e.target.value
                                                                    },
                                                                    value: this.model.email,
                                                                    type: 'text',
                                                                    label: 'Email',
                                                                    placeholder: '請輸入Email',
                                                                    validate: () => {
                                                                        this.model.valid('email')
                                                                    },
                                                                    error: this.model.error('email'),
                                                                })
                                                            ]
                                                        )
                                                    ),
                                                    m("div.form-row",
                                                        m("div.form-group.col-6.col-md-3",
                                                            [
                                                                m("label",
                                                                    "驗證碼"
                                                                ),
                                                                m("input.form-control[type='text']", {
                                                                    placeholder: '請輸入驗證碼',
                                                                    value: this.model.material,
                                                                    class: classNames({
                                                                        "is-invalid": this.model.error('captcha')
                                                                    }),
                                                                    oninput: (e) => {
                                                                        this.model.captcha = e.target.value
                                                                    },
                                                                }),
                                                                m(".text-danger", {
                                                                    class: classNames({
                                                                        "d-none": (!this.model.captcha)
                                                                    })
                                                                }, this.model.error('captcha')),

                                                                // (this.model.errors && this.model.errors['captcha']) ? m(".text-danger", this.model.errors['captcha'][0]) : '',
                                                            ]
                                                        ),
                                                        m("div.form-group.col-6.d-flex.align-items-bottom.col-md-3",
                                                            [
                                                                m("img.w-100", {
                                                                    style: {
                                                                        "cursor": "pointer"
                                                                    },
                                                                    src: window.Captcha,
                                                                    onclick: (e) => {
                                                                        e.preventDefault();
                                                                        window.Captcha += Math.random()
                                                                    },
                                                                    // oncreate(vnode) {
                                                                    //     window.Captcha += Math.random()
                                                                    // }
                                                                })
                                                            ]
                                                        )
                                                    ),
                                                    m("div.form-row",
                                                        m("div.form-group.col",
                                                            m("input.btn.btn-primary[type='submit'][value='確認送出'][data-loading-text='Loading...']", {
                                                                onclick: (e) => {
                                                                    e.preventDefault();
                                                                    if (this.model.validate()) {
                                                                        // console.log(this.model.validate())
                                                                        return false
                                                                    }
                                                                    if (!this.send_request){
                                                                        this.send_request = true
                                                                        if (this.elseone != "") {
                                                                            this.model.qone.push(this.elseone)
                                                                        }
                                                                        if (this.elsetwo != "") {
                                                                            this.model.qtwo.push(this.elsetwo)
                                                                        }
                                                                        if (this.elsethree != "") {
                                                                            this.model.qthree.push(this.elsethree)
                                                                        }
                                                                        if (this.elsefour != "") {
                                                                            this.model.qfour.push(this.elsefour)
                                                                        }

                                                                        this.model.qone = JSON.stringify(this.model.qone)
                                                                        this.model.qtwo = JSON.stringify(this.model.qtwo)
                                                                        this.model.qthree = JSON.stringify(this.model.qthree)
                                                                        this.model.qfour = JSON.stringify(this.model.qfour)

                                                                        this.model.save().then((response) => {
                                                                            swal({
                                                                                title: "報名成功！!",
                                                                                text: "你的訊息我們收到了！",
                                                                                icon: "success",
                                                                                buttons: false,
                                                                                timer: 1800,
                                                                            })
                                                                            this.model.qone = JSON.parse(this.model.qone)
                                                                            this.model.qtwo = JSON.parse(this.model.qtwo)
                                                                            this.model.qthree = JSON.parse(this.model.qthree)
                                                                            this.model.qfour = JSON.parse(this.model.qfour)

                                                                            if (this.elseone != "") {
                                                                                this.model.qone.splice(this.model.qone.indexOf(this.elsefour), 1)
                                                                            }
                                                                            if (this.elsetwo != "") {
                                                                                this.model.qtwo.splice(this.model.qtwo.indexOf(this.elsetwo), 1)
                                                                            }
                                                                            if (this.elsethree != "") {
                                                                                this.model.qthree.splice(this.model.qthree.indexOf(this.elsethree), 1)
                                                                            }
                                                                            if (this.elsefour != "") {
                                                                                this.model.qfour.splice(this.model.qfour.indexOf(this.elsefour), 1)
                                                                            }

                                                                            this.send_request = false

                                                                        })

                                                                    }else{
                                                                        swal({
                                                                            title: "報名中..",
                                                                            text: "訊息接收..請稍後...",
                                                                            icon: "warning",
                                                                            buttons: false,
                                                                            timer: 800,
                                                                        })
                                                                    }
                                                                        
                                                                }
                                                            })
                                                        )
                                                    )
                                                ]
                                            )
                                        )
                                    )
                                )
                            )
                        ]
                    )
                )
            )
            ])
        ]
    }
}

export default DashboardPage