import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'

const constraints = {
    name: {
        presence: {
            allowEmpty: false,
            message: "^請輸入名字"
        }
    },
    idencard: {
        presence: {
            allowEmpty: false,
            message: "^請輸入身分證字號"
        }
    },
    email: {
        presence: {
            allowEmpty: false,
            message: "^請輸入Email"
        },
        email: {
            message: "^電子信箱格式不正確"
        }
    },

    captcha: (value, attributes, attributeName, options, constraints) => {
        if (value) {
            Profile.verific(value).then((response) => {
                if (response.status == "failed") {
                    attributes.errors["captcha"] = ["請輸入正確驗證碼！"]
                } 
            })
        } else {
            return {
                presence: {
                    allowEmpty: false,
                    message: "^請輸入驗證碼"
                },
            }
        }
    },
}

class Profile extends BaseModel {

    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
        this.id = args.id || '';
        this.name = args.name || '';
        this.idencard = args.idencard || null;
        this.organization = args.organization || '';
        this.title = args.title || '';
        this.email = args.email || '';
        this.qone = args.qone || [];
        this.qtwo = args.qtwo || [];
        this.qthree = args.qthree || [];
        this.qfour = args.qfour || [];

        this.isqone = args.isqone || false;
        this.isqtwo = args.isqtwo || false;
        this.isqthree = args.isqthree || false;
        this.isqfour = args.isqfour || false;

    }

    save() {
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/project`,
            data: this
        })
    }

    static verific(verific) {
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/verific/${verific}`,
        }).then((response) => {
            return response
        })
    }

}

export default Profile