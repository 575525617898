import m from 'mithril'
import isMobile from 'lib/detect-mobile'
import FormBox from './formbox'
import {
   Authority,
   User,
} from 'app/models'

const root = document.querySelector('replace')


window.isMobile = isMobile()

const managerRoutes = {
    '/': FormBox,
}

export function render() {
    m.mount(root, FormBox)
}
